<template>
  <div>
    <div class="container">
      <div class="header1">
        <div
          class="flex flex-wrap items-center justify-center"
          style="padding-left: 135px; padding-right: 135px"
        >
          <div
            class="lg:w-1/6 xl:w-1/6 2xl:w-1/6"
            align="center"
            v-for="(item, index) in items"
            :key="index"
          >
            <div v-if="index === circleActive">
              <div class="flex items-center justify-center">
                <div
                  :class="`circleIconActive flex items-center justify-center`"
                >
                  {{ index + 1 }}
                </div>
              </div>
            </div>

            <div v-else>
              <div v-if="index === changeCircle" :class="`circleIcon flex items-center justify-center`" style="background: #5AD16D;">
                <font-awesome-icon icon="check" color="white" />
              </div>

              <div v-else-if="index < changeCircle" :class="`circleIcon flex items-center justify-center`" style="background: #5AD16D;">
                <font-awesome-icon icon="check" color="white" />
              </div>

              <div v-else :class="`circleIcon flex items-center justify-center`">
                {{ index + 1 }}
              </div>
            </div>

            <!-- <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div> -->

            <div
              v-if="index === detailActive"
              class="detailActive flex items-center justify-center"
            >
              {{ item.title }}
            </div>

            <div v-else class="detail flex items-center justify-center">
              <div v-if="index === changeTitle" style="color: #5AD16D;">
                {{ item.title }}
              </div>

              <div v-else-if="index < changeTitle" style="color: #5AD16D;">
                {{ item.title }}
              </div>

              <div v-else>
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row" style="margin-top: 32px">
          <div class="flex flex-row items-center w-2/3">
            <div class="header1Title">
              {{titleHeader}}
            </div>
          </div>

          <div
            class="text-right w-1/3 flex items-center justify-end"
            style="margin-right: 73px"
          >
            <div v-for="(items, index) in loopCircleSmall" :key="index">
              <div v-if="index === circleSmall" class="circleSmall flex items-center justify-center">
                {{ index + 1 }}
              </div>

              <div
                v-else
                class="circleSmallActive flex items-center justify-center"
              >
                {{ index + 1 }}
              </div>
            </div>

            <!-- <div class="circleSmallActive flex items-center justify-center">
              2
            </div>

            <div class="circleSmallActive flex items-center justify-center">
              3
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["titleHeader", "circleActive",'changeCircle', "detailActive", 'changeTitle', 'loopCircleSmall', 'circleSmall',],
  data() {
    return {
      items: [
        {
          title: "ข้อมูลเบื้องต้น",
        },
        {
          title: "รายละเอียดโครงการ",
        },
        {
          title: "ค่าใช้จ่ายในโครงการ",
        },
        {
          title: "ค่าใช้จ่ายในโครงการ",
        },
        {
          title: "คำรับรองของผู้ขอทุน",
        },
        {
          title: "สรุปรายละเอียด",
        },
      ],
    };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
  margin-top: 36px;
}

.header1 {
  padding-top: 36px;
  margin-bottom: 36px;
  width: 100%;
  height: 100%;

  background: rgba(199, 179, 232, 0.5);
  border-radius: 20px;
  padding-bottom: 34px;
}

.circleIcon {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background: #ffffff;
  color: #ab84e8;
}

.circleIconActive {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  background: #573c81;
  color: white;
}

.detailActive {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #573c81;
  margin-top: 4px;
}

.detail {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ab84e8;
  margin-top: 4px;
}

.header1Title {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #573c81;
  margin-left: 70px;
}

.circleSmall {
  width: 29px;
  height: 29px;
  background: #fa4238;
  border-radius: 100%;
  color: white;
  margin-right: 16px;
}

.circleSmallActive {
  width: 29px;
  height: 29px;
  background: #ffffff;
  border-radius: 100%;
  color: #fa7069;
  margin-right: 16px;
}
</style>