
<template>
  <div>
    <header1Component
      :circleActive="2"
      :detailActive="2"
      :loopCircleSmall="5"
      :circleSmall="0"
      :changeCircle="1"
      :changeTitle="1"
    />
    <div class="container">
      <div class="card2">
        <div class="title conditionCard2">
          <div class="flex flex-row w-full">
            <div class="w-1/2" align="center">รายการ</div>
            <div class="w-1/2" align="center">จำนวนเงิน</div>
          </div>
        </div>

        <hr style="margin-top: 8px; margin-bottom: 13px" />

        <div class="title conditionCard2" style="margin-left: 33px; margin-right: 33px;">
          <div class="flex flex-row w-full">
            <div class="w-1/2" align="left">
              1. ค่าตอบแทนบุคลากรหรือค่าจ้างนักวิจัย
            </div>
            <div class="w-1/2" align="right">
              <div class="flex flex-wrap w-full">
                <div class="w-1/3">รวมทั้งสิ้น</div>
                <div class="w-1/3">{{ getResult1 + getResult2 }}</div>
                <div class="w-1/3">บาท</div>
              </div>
            </div>
          </div>
        </div>

        <hr style="margin-top: 13px; margin-bottom: 11px" />

        <div
          v-for="(item, index) in personnelOperationCostDetails1"
          :key="index"
        >
          <div class="title conditionCard2" style="margin-left: 33px; margin-right: 33px; margin-bottom: 32px;">
            <div class="flex flex-row w-full">
              <div class="w-1/2" align="left">
                1.1 ค่าตอบแทนบุคลากร / ค่าจ้าง
              </div>
              <div class="w-1/2" align="right">
                <div class="flex flex-wrap w-full">
                  <div class="w-1/3">รวมทั้งสิ้น</div>
                  <div class="w-1/3">
                    {{
                      personnelOperationCostDetails1[index].propleNumber *
                      personnelOperationCostDetails1[index].timePeriod *
                      personnelOperationCostDetails1[index].wagesPerMonth
                    }}
                  </div>
                  <div class="w-1/3">บาท</div>
                </div>
              </div>
            </div>
          </div>

          <div class="conditionCard2 flex flex-row" style="margin-bottom: 16px">
            <div
              class="content flex items-center justify-end"
              style="
                color: #242424;
                height: 50px;
                margin-right: 25px;
                width: 250px;
              "
            >
              ตำแหน่งในโครงการ
            </div>

            <div class="w-full" style="margin-right: 25px">
              <input
                type="text"
                placeholder="ตำแหน่งในโครงการ"
                v-model="personnelOperationCostDetails1[index].positionProject"
              />
            </div>

            <div class="flex items-center" style="height: 50px">
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                :style="`margin-left: 16px; ${
                  index !== 0
                    ? 'background-color: red;'
                    : 'background-color: white;'
                }`"
                @click="
                  `${
                    index !== 0
                      ? deletePersonnelOperationCostDetails1(index)
                      : ''
                  }`
                "
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>
            </div>
          </div>

          <div class="conditionCard2 flex flex-row" style="margin-bottom: 16px">
            <div
              class="content flex items-center justify-end"
              style="
                color: #242424;
                height: 50px;
                margin-right: 25px;
                width: 250px;
              "
            >
              ปริญญา
            </div>

            <div class="w-full" style="margin-right: 25px">
              <select
                name=""
                id=""
                v-model="personnelOperationCostDetails1[index].degree"
              >
                <option value=""></option>
                <option value="อนุปริญญา">อนุปริญญา</option>
                <option value="ปริญญาตรี">ปริญญาตรี</option>
                <option value="ปริญญาตรี แบบเกียรตินิยม">
                  ปริญญาตรี แบบเกียรตินิยม
                </option>
                <option value="อนุปริญญาโท">อนุปริญญาโท</option>
                <option value="ปริญญาโท">ปริญญาโท</option>
                <option value="ปริญญาเอก">ปริญญาเอก</option>
              </select>
            </div>

            <div class="flex items-center" style="height: 50px">
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                :style="`margin-left: 16px; ${
                  index !== 0
                    ? 'background-color: white;'
                    : 'background-color: white;'
                }`"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>
            </div>
          </div>

          <div class="conditionCard2 flex flex-row" style="margin-bottom: 16px">
            <div
              class="content flex items-center justify-end"
              style="
                color: #242424;
                height: 50px;
                margin-right: 25px;
                width: 250px;
              "
            >
              สาขา
            </div>

            <div class="w-full" style="margin-right: 25px">
              <input
                type="text"
                placeholder="สาขา"
                v-model="personnelOperationCostDetails1[index].branch"
              />
            </div>

            <div class="flex items-center" style="height: 50px">
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                :style="`margin-left: 16px; ${
                  index !== 0
                    ? 'background-color: white;'
                    : 'background-color: white;'
                }`"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="lg:w-1/2">
              <div
                class="conditionCard2 flex flex-row"
                style="margin-bottom: 16px"
              >
                <div
                  class="content flex items-center justify-end"
                  style="
                    color: #242424;
                    height: 50px;
                    margin-right: 25px;
                    width: 412px;
                  "
                >
                  ประสบการณ์อย่างน้อย
                </div>

                <div class="w-full" style="margin-right: 50px">
                  <input
                    type="text"
                    placeholder="0"
                    v-model="
                      personnelOperationCostDetails1[index].atLeastExperience
                    "
                  />
                </div>

                <div class="flex items-center content">ปี</div>
              </div>
            </div>

            <div class="lg:w-1/2">
              <div
                class="conditionCard2 flex flex-row"
                style="margin-bottom: 16px"
              >
                <div
                  class="content flex items-center justify-end"
                  style="
                    color: #242424;
                    height: 50px;
                    margin-right: 25px;
                    width: 250px;
                  "
                >
                  จำนวน
                </div>

                <div class="w-full" style="margin-right: 61px">
                  <input
                    type="text"
                    placeholder="0"
                    v-model="personnelOperationCostDetails1[index].propleNumber"
                  />
                </div>

                <div class="flex items-center">คน</div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap" style="margin-bottom: 36px">
            <div class="lg:w-1/2">
              <div
                class="conditionCard2 flex flex-row"
                style="margin-bottom: 16px"
              >
                <div
                  class="content flex items-center justify-end"
                  style="
                    color: #242424;
                    height: 50px;
                    margin-right: 25px;
                    width: 412px;
                  "
                >
                  ระยะเวลา
                </div>

                <div class="w-full" style="margin-right: 20px">
                  <input
                    type="text"
                    placeholder="0"
                    v-model="personnelOperationCostDetails1[index].timePeriod"
                  />
                </div>

                <div class="flex items-center content">เดือน</div>
              </div>
            </div>

            <div class="lg:w-1/2">
              <div
                class="conditionCard2 flex flex-row"
                style="margin-bottom: 16px"
              >
                <div
                  class="content flex items-center justify-end"
                  style="
                    color: #242424;
                    height: 50px;
                    margin-right: 25px;
                    width: 250px;
                  "
                >
                  เดือนละ
                </div>

                <div class="w-full" style="margin-right: 54px">
                  <input
                    type="text"
                    placeholder="0"
                    v-model="
                      personnelOperationCostDetails1[index].wagesPerMonth
                    "
                  />
                </div>

                <div class="flex items-center">บาท</div>
              </div>
            </div>
          </div>

          <div
            class="conditionCard2 title flex flex-row"
            style="margin-bottom: 32px"
          >
            <div style="margin-right: 36px">รวม</div>
            <div style="margin-right: 36px">
              {{
                personnelOperationCostDetails1[index].propleNumber *
                personnelOperationCostDetails1[index].timePeriod *
                personnelOperationCostDetails1[index].wagesPerMonth
              }}
            </div>
            <div>บาท</div>
          </div>

          <div
            class="conditionCard2 content"
            style="color: #242424; margin-bottom: 15px"
          >
            แหล่งข้องมูลอ้างอิง
          </div>

          <div class="conditionCard2" style="margin-bottom: 32px">
            <checkboxComponent
              :checkboxId="`checkPersonnelOperationCostDetails1${index}`"
              :name="`หลักเกณฑ์ อัตราค่าใช้จ่ายและแนวทางการพิจารณางบประมาณรายจ่ายประจำปี การฝึกอบรม สัมนาการโฆษณา ประชาสัมพันธ์ การจ้างที่ปรึกษาคชจ.การ
เดินทางไปต่างประเทศ`"
              :value="`สิทธิ์บัตร`"
              :underline="true"
              @click="
                checkboxCondition1(
                  `checkPersonnelOperationCostDetails1${index}`,
                  index
                )
              "
            />
          </div>

          <div class="conditionCard2" v-if="index === 0">
            <div
              class="flex flex-row items-center justify-center"
              style="height: 50px; margin-top: 36px; margin-bottom: 36px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                style="margin-right: 16px"
                @click="addpersonnelOperationCostDetails1()"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="plus"
                  color="white"
                />
              </div>

              <div class="titleCircle flex items-center">
                เพิ่มค่าตอบแทนบุคลากร
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div
          v-for="(item, index) in personnelOperationCostDetails2"
          :key="index"
        >
          <div class="title conditionCard2" style="margin-left: 33px; margin-right: 33px; margin-bottom: 32px;">
            <div class="flex flex-row w-full">
              <div class="w-1/2" align="left">
                1.2 ค่าตอบแทนวิทยากร
              </div>
              <div class="w-1/2" align="right">
                <div class="flex flex-wrap w-full">
                  <div class="w-1/3">รวมทั้งสิ้น</div>
                  <div class="w-1/3">
                    {{
                      personnelOperationCostDetails2[index].propleNumber *
                      personnelOperationCostDetails2[index].timePeriod *
                      personnelOperationCostDetails2[index].wagesPerHour
                    }}
                  </div>
                  <div class="w-1/3">บาท</div>
                </div>
              </div>
            </div>
          </div>

          <div class="conditionCard2 flex flex-row" style="margin-bottom: 16px">
            <div
              class="content flex items-center justify-end"
              style="
                color: #242424;
                height: 50px;
                margin-right: 25px;
                width: 250px;
              "
            >
              ตำแหน่งในโครงการ
            </div>

            <div class="w-full" style="margin-right: 25px">
              <input
                type="text"
                placeholder="ตำแหน่งในโครงการ"
                v-model="personnelOperationCostDetails2[index].positionProject"
              />
            </div>

            <div class="flex items-center" style="height: 50px">
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                :style="`margin-left: 16px; ${
                  index !== 0
                    ? 'background-color: red;'
                    : 'background-color: white;'
                }`"
                @click="
                  `${
                    index !== 0
                      ? deletePersonnelOperationCostDetails2(index)
                      : ''
                  }`
                "
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>
            </div>
          </div>

          <div class="conditionCard2 flex flex-row" style="margin-bottom: 16px">
            <div
              class="content flex items-center justify-end"
              style="
                color: #242424;
                height: 50px;
                margin-right: 25px;
                width: 250px;
              "
            >
              ปริญญา
            </div>

            <div class="w-full" style="margin-right: 25px">
              <select
                name=""
                id=""
                v-model="personnelOperationCostDetails2[index].degree"
              >
                <option value=""></option>
                <option value="อนุปริญญา">อนุปริญญา</option>
                <option value="ปริญญาตรี">ปริญญาตรี</option>
                <option value="ปริญญาตรี แบบเกียรตินิยม">
                  ปริญญาตรี แบบเกียรตินิยม
                </option>
                <option value="อนุปริญญาโท">อนุปริญญาโท</option>
                <option value="ปริญญาโท">ปริญญาโท</option>
                <option value="ปริญญาเอก">ปริญญาเอก</option>
              </select>
            </div>

            <div class="flex items-center" style="height: 50px">
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                :style="`margin-left: 16px; ${
                  index !== 0
                    ? 'background-color: white;'
                    : 'background-color: white;'
                }`"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>
            </div>
          </div>

          <div class="conditionCard2 flex flex-row" style="margin-bottom: 16px">
            <div
              class="content flex items-center justify-end"
              style="
                color: #242424;
                height: 50px;
                margin-right: 25px;
                width: 250px;
              "
            >
              สาขา
            </div>

            <div class="w-full" style="margin-right: 25px">
              <input
                type="text"
                placeholder="สาขา"
                v-model="personnelOperationCostDetails2[index].branch"
              />
            </div>

            <div class="flex items-center" style="height: 50px">
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                :style="`margin-left: 16px; ${
                  index !== 0
                    ? 'background-color: white;'
                    : 'background-color: white;'
                }`"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>
            </div>
          </div>

          <div class="flex flex-wrap">
            <div class="lg:w-1/2">
              <div
                class="conditionCard2 flex flex-row"
                style="margin-bottom: 16px"
              >
                <div
                  class="content flex items-center justify-end"
                  style="
                    color: #242424;
                    height: 50px;
                    margin-right: 25px;
                    width: 412px;
                  "
                >
                  ประสบการณ์อย่างน้อย
                </div>

                <div class="w-full" style="margin-right: 50px">
                  <input
                    type="text"
                    placeholder="0"
                    v-model="personnelOperationCostDetails2[index].atLeastExperience"
                  />
                </div>

                <div class="flex items-center content">ปี</div>
              </div>
            </div>

            <div class="lg:w-1/2">
              <div
                class="conditionCard2 flex flex-row"
                style="margin-bottom: 16px"
              >
                <div
                  class="content flex items-center justify-end"
                  style="
                    color: #242424;
                    height: 50px;
                    margin-right: 25px;
                    width: 250px;
                  "
                >
                  จำนวน
                </div>

                <div class="w-full" style="margin-right: 61px">
                  <input
                    type="text"
                    placeholder="0"
                    v-model="personnelOperationCostDetails2[index].propleNumber"
                  />
                </div>

                <div class="flex items-center">คน</div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap" style="margin-bottom: 36px">
            <div class="lg:w-1/2">
              <div
                class="conditionCard2 flex flex-row"
                style="margin-bottom: 16px"
              >
                <div
                  class="content flex items-center justify-end"
                  style="
                    color: #242424;
                    height: 50px;
                    margin-right: 25px;
                    width: 412px;
                  "
                >
                  ระยะเวลา
                </div>

                <div class="w-full" style="margin-right: 11px">
                  <input
                    type="text"
                    placeholder="0"
                    v-model="personnelOperationCostDetails2[index].timePeriod"
                  />
                </div>

                <div class="flex items-center content">ชั่วโมง</div>
              </div>
            </div>

            <div class="lg:w-1/2">
              <div
                class="conditionCard2 flex flex-row"
                style="margin-bottom: 16px"
              >
                <div
                  class="content flex items-center justify-end"
                  style="
                    color: #242424;
                    height: 50px;
                    margin-right: 25px;
                    width: 250px;
                  "
                >
                  ชั่วโมงละ
                </div>

                <div class="w-full" style="margin-right: 54px">
                  <input
                    type="text"
                    placeholder="0"
                    v-model="personnelOperationCostDetails2[index].wagesPerHour"
                  />
                </div>

                <div class="flex items-center">บาท</div>
              </div>
            </div>
          </div>

          <div
            class="conditionCard2 title flex flex-row"
            style="margin-bottom: 32px"
          >
            <div style="margin-right: 36px">รวม</div>
            <div style="margin-right: 36px">
              {{
                personnelOperationCostDetails2[index].propleNumber *
                personnelOperationCostDetails2[index].timePeriod *
                personnelOperationCostDetails2[index].wagesPerHour
              }}
            </div>
            <div>บาท</div>
          </div>

          <div
            class="conditionCard2 content"
            style="color: #242424; margin-bottom: 15px"
          >
            แหล่งข้องมูลอ้างอิง
          </div>

          <div class="conditionCard2" style="margin-bottom: 32px">
            <checkboxComponent
              :checkboxId="`checkPersonnelOperationCostDetails2${index}`"
              :name="`หลักเกณฑ์ อัตราค่าใช้จ่ายและแนวทางการพิจารณางบประมาณรายจ่ายประจำปี การฝึกอบรม สัมนาการโฆษณา ประชาสัมพันธ์ การจ้างที่ปรึกษาคชจ.การ
เดินทางไปต่างประเทศ`"
              :value="`สิทธิ์บัตร`"
              :underline="true"
              @click="
                checkboxCondition2(
                  `checkPersonnelOperationCostDetails2${index}`,
                  index
                )
              "
            />
          </div>

          <div class="conditionCard2" v-if="index === 0">
            <div
              class="flex flex-row items-center justify-center"
              style="height: 50px; margin-top: 36px; margin-bottom: 36px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                style="margin-right: 16px"
                @click="addpersonnelOperationCostDetails2()"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="plus"
                  color="white"
                />
              </div>

              <div class="titleCircle flex items-center">
                เพิ่มค่าตอบแทนบุคลากร
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="flex flex-row">
          <div
            class="w-1/2 flex flex-start justify-start"
            style="margin-left: 72px; margin-top: 81px"
          >
            <button class="buttonPrevious" @click="createRequest()">
              ก่อนหน้า
            </button>
          </div>

          <div
            class="w-1/2 flex items-end justify-end"
            style="margin-right: 72px; margin-top: 81px"
          >
            <button class="buttonNext" @click="updateRequest()">ถัดไป</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useRequest from '../../hooks/useRequest';
import header1Component from "@/components/capital/header1Component";
import checkboxComponent from "@/components/checkbox/checkboxComponent";
export default {
  components: {
    header1Component,
    checkboxComponent,
  },
  mounted() {
    this.getData();
  },
  computed: {
    getResult1() {
      let result = 0;
      for (let i = 0; i !== this.personnelOperationCostDetails1.length; i++) {
        result =
          result +
          this.personnelOperationCostDetails1[i].propleNumber *
            this.personnelOperationCostDetails1[i].timePeriod *
            this.personnelOperationCostDetails1[i].wagesPerMonth;
      }
      return result;
    },
    getResult2() {
      let result = 0;
      for (let i = 0; i < this.personnelOperationCostDetails2.length; i++) {
        result =
          result +
          this.personnelOperationCostDetails2[i].propleNumber *
            this.personnelOperationCostDetails2[i].timePeriod *
            this.personnelOperationCostDetails2[i].wagesPerHour;
      }
      return result;
    },
  },
  methods: {
    addpersonnelOperationCostDetails1() {
      this.personnelOperationCostDetails1.push({
        positionProject: null,
        degree: null,
        branch: null,
        atLeastExperience: null,
        propleNumber: null,
        timePeriod: null,
        wagesPerMonth: null,
        referenceSourceAccept: null,
      });
    },
    deletePersonnelOperationCostDetails1(id) {
      this.personnelOperationCostDetails1.splice(id, 1);
    },
    addpersonnelOperationCostDetails2() {
      this.personnelOperationCostDetails2.push({
        positionProject: null,
        degree: null,
        branch: null,
        atLeastExperience: null,
        propleNumber: null,
        timePeriod: null,
        wagesPerHour: null,
        referenceSourceAccept: null,
      });
    },
    deletePersonnelOperationCostDetails2(id) {
      this.personnelOperationCostDetails2.splice(id, 1);
    },
    checkboxCondition1(id, index) {
      if (document.getElementById(id).checked === true) {
        this.personnelOperationCostDetails1[index].referenceSourceAccept = true;
      } else {
        this.personnelOperationCostDetails1[index].referenceSourceAccept = null;
      }
    },
    checkboxCondition2(id, index) {
      if (document.getElementById(id).checked === true) {
        this.personnelOperationCostDetails2[index].referenceSourceAccept = true;
      } else {
        this.personnelOperationCostDetails2[index].referenceSourceAccept = null;
      }
    },
    getData() {
      useRequest.getProjectId(this.id).then(response => {
        this.personnelOperationCostDetails1 = response.data.personnelOperationCostDetails1;
        this.personnelOperationCostDetails2 = response.data.personnelOperationCostDetails2;
        
        for (let i = 0; i !== this.personnelOperationCostDetails1.length; i++) {
          if (this.personnelOperationCostDetails1[i].referenceSourceAccept === true) {
            document.getElementById(`checkPersonnelOperationCostDetails1${i}`).checked = true;
          }
        }

        for (let i = 0; i !== this.personnelOperationCostDetails2.length; i++) {
          if (this.personnelOperationCostDetails1[i].referenceSourceAccept === true) {
            document.getElementById(`checkPersonnelOperationCostDetails2${i}`).checked = true;
          }
        }

      });
    },
    updateRequest() {
      let data = {
        personnelOperationCostDetails1: this.personnelOperationCostDetails1,
        personnelOperationCostDetails2: this.personnelOperationCostDetails2,
      };
      useRequest.updateProject(this.id, data).then(response => {
        console.log(response.data)
      });
    }
  },
  setup() {
    const personnelOperationCostDetails1 = ref([
      {
        positionProject: null,
        degree: null,
        branch: null,
        atLeastExperience: null,
        propleNumber: null,
        timePeriod: null,
        wagesPerMonth: null,
        referenceSourceAccept: null,
      },
    ]);
    const personnelOperationCostDetails2 = ref([
      {
        positionProject: null,
        degree: null,
        branch: null,
        atLeastExperience: null,
        propleNumber: null,
        timePeriod: null,
        wagesPerHour: null,
        referenceSourceAccept: null,
      },
    ]);
    return { personnelOperationCostDetails1, personnelOperationCostDetails2 };
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  }
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.card2 {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  padding-top: 34px;
  padding-bottom: 36px;
  margin-bottom: 36px;
}

.conditionCard2 {
  margin-left: 73px;
  margin-right: 73px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #242424;
  margin-bottom: 10px;
}

hr {
  margin-left: 33px;
  margin-right: 33px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #c4c4c4;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #575757;
}

.contentActive {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
  width: 300px;
  margin-right: 24px;
  margin-left: 48px;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

button {
  width: 195px;
  height: 50px;
  color: white;
  background: #573c81;
  border-radius: 5px;
}

.buttonPrevious {
  width: 195px;
  height: 50px;
  color: #573c81;
  background: #ffffff;
  border: 1px solid #573c81;
  box-sizing: border-box;
  border-radius: 5px;
}

select {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

.circle {
  width: 40px;
  height: 40px;
  color: white;
  background: #573c81;
  border-radius: 100%;
  margin-top: 36px;
  margin-bottom: 36px;
  font-size: 36px;
}

.titleCircle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
}
</style>