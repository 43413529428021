import axios from 'axios';

const token = localStorage.getItem("@auth");

let config_token = {
    headers: {
        'Authorization': 'Bearer ' + token,
    }
}

const getProjectId = async (id) => {
    let auth = await axios.get(`${process.env.VUE_APP_URL_API}/request/project/${id}`, config_token);
    return auth;
}

const createProject = async (section26, status, nameOfAttorney, projectNameTh, projectNameEn, projectDurationDay, addressContact1, addressContact2) => {
    let auth = await axios.post(`${process.env.VUE_APP_URL_API}/request/create`, {
        userId: localStorage.getItem('_id'),
        section26: section26,
        status: status,
        nameOfAttorney: nameOfAttorney,
        projectNameTh: projectNameTh,
        projectNameEn: projectNameEn,
        projectDurationDay: projectDurationDay,
        addressContact1: addressContact1,
        addressContact2: addressContact2,
        pageStatus: 1,
    }, config_token);
    return auth;
}

const updateProject = async (_id, data) => {
    let auth = await axios.put(`${process.env.VUE_APP_URL_API}/request/update/${_id}`, data, config_token);
    return auth;
}

export default {
    getProjectId,
    createProject,
    updateProject,
}